import {last} from "common/lib/ext/array";
import {dig} from "common/lib/ext/object";
import I18nData from "./i18n-data.js.erb";

const parseOptions = (result, options) => {
  if(!result || typeof result !== "string") { return result; }

  return result.replace(/%\{[a-z_]*\}/g, (r) => options[r.slice(2, -1)]);
};

export default class I18n {
  constructor(locale, organization) {
    this.locale = locale;
    this.options = organization.options || {i18ns: {}};
  }
  t(key, options = {}) {
    const keys = key ? key.split(".") : [];
    const result = this.tOption(last(keys)) || dig(I18nData[this.locale.rails_code], ...keys);
    return parseOptions(result, {...this.options, ...options});
  }
  tOption(_field, options = {}) {
    let field = _field;
    if(["department", "external_id", "package"].includes(field)) { field += "_label"; }
    const result = this.options.i18ns[this.locale.rails_code] || {};
    return parseOptions(result[field] || this.options[field], options);
  }
}
