const hexToRGB = (hexColor) => {
  const rgb = hexColor.replace("#", "").match(/.{1,2}/g);

  rgb.forEach((color, index) => {
    rgb[index] = parseInt(color, 16);
  });
  return rgb;
};

const getLightnessFromHex = (hexColor) => hexToRGB(hexColor).reduce((a, b) => a + b);
const isWhite = (hexColor) => getLightnessFromHex(hexColor) >= 700;

export {getLightnessFromHex, hexToRGB, isWhite};
