import {useRouteError} from "react-router-dom";

export default function RouterErrorBoundary() {
  const {error} = useRouteError();
  console.log(error); // eslint-disable-line no-console

  return (
    <div className="container mx-auto my-20 text-center">
      <h1>Error</h1>
      <p>Please reload the page and try again. If the problem persists, please contact support</p>
    </div>
  );
}
