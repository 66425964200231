export function dig(object, ...keys) {
  const reducer = (result, key) => (result && result[key] != null ? result[key] : null);

  return keys.reduce(reducer, object);
}
export function isDefined(object) { return Object.is(object, undefined); }
export function isEmpty(object) { return Object.keys(object).length === 0; }
export function mutable(object) {
  if(object === null || typeof object !== "object") { return object; }
  if(Array.isArray(object)) { return object.map((item) => mutable(item)); }

  return Object.fromEntries(
    Object.entries(object).map(([key, value]) => [key, mutable(value)])
  );
}
export function slice(object, properties) {
  return properties.reduce((newObject, key) => (
    {...newObject, [key]: object[key]}
  ), {});
}
export function toQueryString(_object) {
  const keys = Object.keys(_object).filter((key) => _object[key] != null);
  const object = slice(_object, keys);

  return new URLSearchParams(object).toString();
}
